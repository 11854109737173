import * as React from 'react'
import Layout from '../components/layout'
import {content} from '../components/layout.module.css'


const AboutPage = () => {
  return (
    <Layout pageTitle="About Lauren">
      <div className={content}>
		  The AusDICT is the creation of Lauren Sadow. <br/><br/>
	  Lauren was born on Ngunnawal Country, now called Canberra, in Australia. She is a linguist who delights in shining a light on how language shapes how we think about and interact with the world. 
	  In her work, she looks for new ways to help people explore their own internal worlds and learn how to express them to others.  
<br/><br/>

Her primary research area uses the natural semantic metalanguage approach; an approach to culture and semantics which tries to explain complex cultural concepts using only simple, universal words. She applies this approach to common problems of language and culture teaching, and anywhere which can benefit from more accessible explanations. 
<br/>
<br/>
This dictionary website was a result of her PhD research into teacher needs for incorporating the NSM approach into language classrooms.<br/><br/>

In addition to her research, she is an avid creator across mediums, including digital art, textiles, programming, painting, and drawing. <br/><br/>

If you'd like to learn more about Lauren, visit her homepage at <a href="https://www.laurensadow.com" target="_blank" rel="noreferrer">www.laurensadow.com</a><br/><br/>
If you'd like to learn more about her research, you can visit the Standard Translatable English homepage at <a href="https://www.translatableenglish.com" target="_blank" rel="noreferrer">www.translatableenglish.com</a>
      </div>
    </Layout>
  )
}

export default AboutPage